import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { MdMenu, MdClose } from "react-icons/md";
import css from "@emotion/css";

const Header = ({ siteTitle }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <header
      css={{
        background: `black`,
        marginBottom: `32px`,
      }}
    >
      <div
        css={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `16px 16px`,
        }}
      >
        <div
          css={{
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <h1 style={{ margin: 0 }}>
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </h1>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            {!menuOpen ? (
              <MdMenu
                color="white"
                size={32 + 16}
                css={css`
                  cursor: pointer;
                `}
                onClick={() => {
                  setMenuOpen(s => !s);
                }}
              />
            ) : (
              <MdClose
                color="white"
                size={32 + 16}
                css={css`
                  cursor: pointer;
                `}
                onClick={() => {
                  setMenuOpen(s => !s);
                }}
              />
            )}
          </div>
        </div>

        <div
          onClick={() => {
            setMenuOpen(s => !s);
          }}
          css={css`
            width: 100%;
            align-items: flex-end;
            flex-direction: column;
            display: ${menuOpen ? "flex" : "none"};
            transition: height 0.5s linear;
            a {
              color: white;
              text-decoration: none;
              font-size: ${16 + 8}px;
              margin-top: 16px;
              font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
                Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                sans-serif;
            }
            a:first-of-type {
              margin-top: 8px;
            }
          `}
        >
          <Link to="/">Home</Link>
          <Link to="/music/">Music</Link>
          <Link to="/portfolio/">Portfolio</Link>
          <Link to="/blog/">Blog</Link>
          <Link to="/videos/">Videos</Link>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
