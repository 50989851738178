import { ReactChild } from "react";

const A = ({
  href,
  children,
  title,
}: {
  href: string;
  children: ReactChild;
  title?: string;
}) => (
  <a href={href} title={title} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

export default A;
