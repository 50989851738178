import React from "react";
import {
  FaSoundcloud,
  FaInstagram,
  FaTwitter,
  FaGithub,
  FaLinkedin,
  FaEnvelope,
} from "react-icons/fa";
import A from "./anchor";

const iconSize = 25;

const Social = ({ color }: { color: string }) => (
  <React.Fragment>
    <A title="Email" href="mailto:rs@robsco.info">
      <FaEnvelope size={iconSize} />
    </A>
    <A title="Twitter" href="https://twitter.com/scobrot">
      <FaTwitter color={color} size={iconSize} />
    </A>
    <A title="Instagram" href="https://www.instagram.com/robsco.info/">
      <FaInstagram color={color} size={iconSize} />
    </A>
    <A title="Soundcloud" href="https://soundcloud.com/robsco">
      <FaSoundcloud color={color} size={iconSize} />
    </A>
    <A title="GitHub" href="https://github.com/robsco-git/">
      <FaGithub color={color} size={iconSize} />
    </A>
    <A
      title="LinkedIn"
      href="https://www.linkedin.com/in/robert-scott-806945183/"
    >
      <FaLinkedin size={iconSize} />
    </A>
  </React.Fragment>
);
export default Social;
