/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";

import Header from "./header";
import "./layout.css";
import css from "@emotion/css";
import Social from "./social";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
      </div>
      <footer
        css={css`
          background-color: black;

          padding-top: 32px;
          padding-bottom: 32px;
          a {
            text-decoration: none;
            color: white;
            font-size: ${16}px;
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
              Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
              sans-serif;
          }
        `}
      >
        <div
          css={css`
            text-align: center;
            a {
              margin-left: 8px;
              margin-right: 8px;
            }
          `}
        >
          <Link to="/">Home</Link>
          <Link to="/music/">Music</Link>
          <Link to="/portfolio/">Portfolio</Link>
          <Link to="/blog/">Blog</Link>
          <Link to="/videos/">Videos</Link>
        </div>
        <div
          css={css`
            text-align: center;
            margin-top: 16px;
            a {
              margin-left: 16px;
              margin-right: 16px;
            }
          `}
        >
          <Social color={"white"} />
        </div>
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
